/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  jQuery.fn.doesExist = function(){
    return jQuery(this).length > 0;
  };
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    isoSetHeight: function() {
      var gridItemHeight = 0;
      var _gItem = $('.grid .grid-height');
      var gridCount = _gItem.length-1;
      $(_gItem).find('.entry-summary').height('auto');
      _gItem.each(function(index){
        var thisHeight = $(this).find('.entry-summary').height() + 81; //bottom padding + read-article height = 81
        --gridCount;
        if(thisHeight > gridItemHeight) {
          gridItemHeight = thisHeight;
        }
        if (!gridCount) {
          $(_gItem).find('.entry-summary').height(gridItemHeight);
          $(_gItem).find('.read-article').css({position: 'absolute', bottom: 33});
        }

      });

    },
    pace: function(){
      if (body.hasClass('.hero-loading')){
        pace.start();
      } else{
        pace.stop();
      }
    },
    formSubmitValidation: function(){
      $('#gform_submit_button_1').attr('disabled', 'disabled');
      $('.gfield_contains_required input, #gform_1 .gfield_contains_required textarea').on('change', function() {
        $('#gform_submit_button_1[style="display: none;"]').attr('disabled', 'disabled');
        $('#gform_submit_button_1[style="display: inline-block;"]').removeAttr('disabled');
      });
    },
    initIsotope: function(){
      $grid = $('.grid').isotope({
        layoutMode: 'packery',
        packery: {
          columnWidth: '.grid-sizer',
          gutter: '.gutter-sizer'
        },
        itemSelector: '.grid-item',
        percentPosition: true
      });
      $grid.imagesLoaded().progress( function() {
        $grid.isotope('layout');
      });
    },
    case_slider_thumb: function(ind, slideThumbs, slideBG) {
      var curInd = ind;
      var nextInd = curInd + 1;
      var prevInd = curInd - 1;
      //console.log(curInd);
      if(nextInd > 8 || nextInd > slideThumbs.length) {
        nextInd = 0;
      }
      if(prevInd < 0) {
        prevInd = slideThumbs.length-1;
      }
      var nextLogo = ($('.flexslider .slides .slide').eq(nextInd).data('nav-thumb') === "" ? nextInd : $('.flexslider .slides .slide').eq(nextInd).data('nav-thumb'));
      var pastLogo = ($('.flexslider .slides .slide').eq(prevInd).data('nav-thumb') === "" ? prevInd : $('.flexslider .slides .slide').eq(prevInd).data('nav-thumb'));
      $('.flex-prev img').attr('src',pastLogo);
      $('.flex-next img').attr('src',nextLogo);
      $('.flexslider').parent().css('background-position-y','-900px').css('background-image','url('+slideBG[curInd]+')').css('background-position-y','center');
    },
    border_height: function(){
      var maxHeight = -1;

      $('.post-loop-article').each(function() {
        maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
      });

      $('.post-loop-column').each(function() {
        $(this).css({"height":maxHeight});
      });
    },
    sliderChange: function(){
      var page_header = $("#page-header");
      var desktop_active = page_header.find(".forcefullwidth_wrapper_tp_banner").eq(0);
      var mobile_active = page_header.find(".forcefullwidth_wrapper_tp_banner").eq(1);
      if (mobile_active.length){
        desktop_active.addClass("desktop-slider-active");
        mobile_active.addClass("mobile-slider-active");
      } else{
        desktop_active.addClass("desktop-slider");
      }

    },
    pageHeader: function(){
      var parentHeight = $('#page-header').height();
      var childHeight = $('.page-header-content').height();
      $('.desktop-hero .page-header-content').css('margin-top', (parentHeight - childHeight) / 2);
      $('.mobile-slider-active .page-header-content').css('margin-top', (parentHeight - childHeight) / 4);
    },
    pageHeaderAlternative: function(){
      var parentHeight = $('#page-header').height() + $('#main-header').height();
      var childHeight = $('.page-header-content').height();
      $('.desktop-hero .page-header-content').css('margin-top', (parentHeight - childHeight) / 2);
      $('.mobile-slider-active .page-header-content').css('margin-top', (parentHeight - childHeight) / 4);
    },
    twoColumnAlign: function(){
      var parentClass = $('.balance-box-js');
      if ( parentClass.doesExist() ){
        var parentHeight = parentClass.height();
        var childHeightLeft = $('.balance-box-js .col-left').height();
        var childHeightRight = $('.balance-box-js .col-right').height();
        if ( parentHeight > childHeightLeft ) {
          $('.balance-box-js .col-left').css('margin-top', (parentHeight - childHeightLeft) / 2);
        }
        if ( parentHeight > childHeightRight ) {
          $('.balance-box-js .col-right').css('margin-top', (parentHeight - childHeightRight) / 2);
        }
      } else{
        return;
      }
    },
    stickyNavbar: function(){
      var logoSrc = $('img.navbar-brand').attr('src');
      var main_header = $('header#main-header');
      // console.log(logoSrc);
      document.onscroll = function() {
        if( $(window).scrollTop() > $('#page-header').height() ) {
          if (main_header.hasClass('navbar-transparent-top') ){
            main_header.removeClass('navbar-transparent-top').addClass('navbar-fixed-top');
          }
          if (main_header.hasClass('navbar-fixed-top') && $('body').hasClass('single-case_study')){
            $('img.navbar-brand').attr('src','/wp-content/themes/sage-master/dist/images/xomad-logo.png');
          }
          else if (main_header.hasClass('navbar-static-top') ){
            main_header.removeClass('navbar-static-top').addClass('navbar-fixed-top');
          }
        }
        else {
          if ($('body').hasClass('single-case_study')) {
            $('img.navbar-brand').attr('src',logoSrc);
          }
          if (main_header.hasClass('transparent') ){
            main_header.removeClass('navbar-fixed-top').addClass('navbar-transparent-top');
          }
          else if (main_header.hasClass('static') ){
            main_header.removeClass('navbar-fixed-top').addClass('navbar-static-top');
          }
        }
      };
    },
    'common': {
      init: function() {
        Sage.border_height();
        Sage.stickyNavbar();
        Sage.formSubmitValidation();
        $(window).resize(function(){
          Sage.border_height();
          Sage.sliderChange();
          if ($(window).width() > 950) {
            Sage.twoColumnAlign();
          }
          else{
            return;
          }
        });

        //if($(window).width() < 500) {
        $(".case-study-heading").fitText(0.8, { minFontSize: '10px', maxFontSize: '52px' });
        $(".about-us #page-header h2").fitText(0.8, { minFontSize: '40px', maxFontSize: '60px' });
        //}
        $('.soc-link').on('click',function(e){
          e.preventDefault();
          console.log($(this).attr('href'));
          window.open($(this).attr('href'),"_blank","width=480,height=300");
        });
        $('.tooltippy-bottom').tooltipster({
          contentAsHTML: true,
          theme: 'tooltipster-borderless',
          trigger: 'click',
          forceSide: true,
          side: 'bottom',
          interactive: true,
          maxWidth: 960,
          viewportAware: false,
          animationDuration: 20,
          functionReady: function(){
            $('.tt-close').click(function(){
              $('.tooltippy-bottom').tooltipster('hide');
            });
          }
        });
        $('.tooltippy-right').tooltipster({
          contentAsHTML: true,
          theme: 'tooltipster-borderless',
          trigger: 'click',
          side: ['right', 'top'],
          minIntersection: 30,
          repositionOnScroll: true,
          maxWidth: 600,
        });
        $('.tooltippy-left').tooltipster({
          contentAsHTML: true,
          theme: 'tooltipster-borderless',
          trigger: 'click',
          side: 'left',
          maxWidth: 600,
        });
        var slideThumbs = [];
        var slideBG = [];
        $('.flexslider .slides .slide').each(function(){
          slideThumbs.push($(this).data('nav-thumb'));
          slideBG.push($(this).data('slide-bg'));
        });
        $('.flexslider').flexslider({
          animation: "fade",
          touch: true,
          controlsContainer: $(".custom-controls-container"),
          customDirectionNav: $(".custom-navigation a"),
          selector: ".slides > .slide",
          start: function() {
            Sage.case_slider_thumb($('.flexslider').data('flexslider').currentSlide, slideThumbs,slideBG);
          },
          after: function(){
            Sage.case_slider_thumb($('.flexslider').data('flexslider').currentSlide, slideThumbs,slideBG);
          }
        });
        // manage the backgrund (phone) image on screen resize. use a slick event for it. otherwise it breaks
        $('.influencer-slider').on('setPosition', function(slick){
          $('.influencer-slider').css('background-size',$('.influencer-img').first().width() * 1.1);
        });
        $('.influencer-slider').slick({
          centerMode: true,
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          centerPadding: '100px',
          slidesToShow: 5,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 5
              }
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
        });
        $('.logos-slider-slick').slick({
          infinite: true,
          speed: 300,
          slidesToShow: 5,
          slidesToScroll: 1,
          nextArrow: '<a class="right carousel-control"><i class="fa fa-long-arrow-right"></i></a>',
          prevArrow: '<a class="left carousel-control"><i class="fa fa-long-arrow-left"></i></a>',
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
              }
            }
          ]
        });

        $(function () {
          $('[data-toggle="popover"]').popover({
            html: true
          });
          /*  event.preventDefault();*/
          $('#influencer-tooltip').tooltipster({
            content: $('#tooltip_content').detach(),
            contentCloning: true,
            trigger: 'click'
          });

        });
        $('.post-loop-column, .grid-item, .related-cases-post-loop-column').on('click', function(){
          window.location = $( this ).find('.entry-summary a').attr("href");
        });
        $('[data-toggle="team-popover"]').popover({
          html : true
        });
        $('#slidemenu').on('show.bs.offcanvas', function (e) {
          $("html").css("overflow-y", "hidden");
        });
        $('#slidemenu').on('hidden.bs.offcanvas', function (e) {
          $("html").css("overflow-y", "auto");
        });

        /*event.preventDefault();*/
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        setInterval(function() {
          Sage.sliderChange();
        },800);
        $(window).load(function(){
          if ($(window).width() > 950) {
            Sage.twoColumnAlign();
          }
          else{
            return;
          }
        });

        $('#myCarousel').carousel({
          interval: 5000,
          pause: 'hover',
          wrap: true
        });

        var slide = $('.carousel .item');
        var slideActive = slide.siblings(':first-child').addClass('active');
        slide.each(function(){
          var next = $(this);
          var last;
          for (var i=0;i<5;i++) {
            next=next.next();
            if (!next.length) {
              next = $(this).siblings(':first');
            }

            last=next.children(':first-child').clone().appendTo($(this));
          }
          last.addClass('rightest');

        });
      }
    },
    'blog': {
      init: function() {

        //        Sage.isoSetHeight();
      },
      finalize: function() {
        Sage.initIsotope();
      }
    },
    'post_type_archive_case_study': {
      init: function() {

        //        Sage.isoSetHeight();
      },
      finalize: function() {
        Sage.initIsotope();
      }
    },
    'archive': {
      init: function() {

        //        Sage.isoSetHeight();
      },
      finalize: function() {
        Sage.initIsotope();
      }
    },
    'case_studies': {
      init: function() {

        //        Sage.isoSetHeight();
      },
      finalize: function() {
        Sage.initIsotope();
      }
    },
    'single_case_study': {
      init: function() {
        //         if ($(window).width() > 767) {
        //            Sage.twoColumnAlign();
        //          }
        //          else{
        //            return;
        //          }
        //        $(window).resize(function(){
        //          if ($(window).width() > 767) {
        //            Sage.twoColumnAlign();
        //          }
        //          else{
        //            return;
        //          }
        //        });
      },
      finalize: function() {

      }
    },
    // Home page
    'home': {
      init: function() {
        $('a.case-arrow').hover(function(){
          $(this).toggleClass('hover-state');
        });
        var moveButton = function(){
          var toRight = 0,
            toLeft = 0;
          if($(window).width() > 767){
            toLeft = $('.case-study-post-header').offset().left - 55;
            toRight = ($(window).width() - ($('.case-study-post-header').offset().left + $('.case-study-post-header').width())) - 55;
          }
          var rightAdjust = $(window).width() - ($('a.case-arrow.next').offset().left + $('a.case-arrow.next').width());
          //			  console.log(toLeft,toRight,rightAdjust);
          if(toRight < - 14){
            toRight = 0;
          }
          $('a.case-arrow.next').css('right', toRight);
          $('a.case-arrow.prev').css('left', toLeft);
        };
        moveButton();
        $(window).resize(function(){
          moveButton();
        });
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  //  $(window).resize(Sage.isoSetHeight);

})(jQuery); // Fully reference jQuery after this point.
